// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["cbtq3oQqo", "HXf22Pb6N", "kar1yBaCN"];

const variantClassNames = {cbtq3oQqo: "framer-v-lmqljm", HXf22Pb6N: "framer-v-am9y3g", kar1yBaCN: "framer-v-i00xbn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {Desktop: "cbtq3oQqo", Phone: "kar1yBaCN", Tablet: "HXf22Pb6N"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "cbtq3oQqo", image: xtJvl913o = {src: new URL("assets/2048/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href} 512w, ${new URL("assets/1024/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href} 1024w, ${new URL("assets/2048/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href} 2048w, ${new URL("assets/4096/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href} 4096w, ${new URL("assets/ETaq7VL19WU4w9AxqyGjyaxZQ.jpg", import.meta.url).href} 8000w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cbtq3oQqo", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-YZW3Z", classNames)} style={{display: "contents"}}>
<Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 5360, intrinsicWidth: 8000, pixelHeight: 5360, pixelWidth: 8000, sizes: "1200px", ...toResponsiveImage(xtJvl913o)}} className={cx("framer-lmqljm", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"cbtq3oQqo"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({HXf22Pb6N: {"data-framer-name": "Tablet", background: {alt: "", fit: "fill", intrinsicHeight: 5360, intrinsicWidth: 8000, pixelHeight: 5360, pixelWidth: 8000, sizes: "810px", ...toResponsiveImage(xtJvl913o)}}, kar1yBaCN: {"data-framer-name": "Phone", background: {alt: "", fit: "fill", intrinsicHeight: 5360, intrinsicWidth: 8000, pixelHeight: 5360, pixelWidth: 8000, sizes: "390px", ...toResponsiveImage(xtJvl913o)}}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-YZW3Z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YZW3Z .framer-fbn9d4 { display: block; }", ".framer-YZW3Z .framer-lmqljm { height: 800px; mix-blend-mode: color-dodge; overflow: hidden; position: relative; width: 1200px; }", ".framer-YZW3Z.framer-v-am9y3g .framer-lmqljm { width: 810px; }", ".framer-YZW3Z.framer-v-i00xbn .framer-lmqljm { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"HXf22Pb6N":{"layout":["fixed","fixed"]},"kar1yBaCN":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"xtJvl913o":"image"}
 */
const FramerxFU5TUVsn: React.ComponentType<Props> = withCSS(Component, css, "framer-YZW3Z") as typeof Component;
export default FramerxFU5TUVsn;

FramerxFU5TUVsn.displayName = "Grain";

FramerxFU5TUVsn.defaultProps = {height: 800, width: 1200};

addPropertyControls(FramerxFU5TUVsn, {variant: {options: ["cbtq3oQqo", "HXf22Pb6N", "kar1yBaCN"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, xtJvl913o: {__defaultAssetReference: "data:framer/asset-reference,ETaq7VL19WU4w9AxqyGjyaxZQ.jpg?originalFilename=AU-FG-Texture1-8K.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerxFU5TUVsn, [])